import React, { useState } from 'react'; // useState 임포트
import { uploadFile } from '../services/api'; // uploadFile 함수 임포트

const FileUpload = ({ directoryId, onCreateFile, onClose }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false); // 로딩 상태 추가

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('업로드할 파일을 선택해주세요.');
      return;
    }

    try {
      setLoading(true); // 업로드 시작 시 로딩 상태 활성화
      await uploadFile(directoryId, selectedFile, setLoading); // uploadFile 함수 사용
      alert('파일 업로드에 성공하였습니다.');
      onCreateFile(selectedFile.name); // 업로드된 파일 추가
      onClose(); // 모달 닫기
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('파일 업로드에 실패하였습니다.');
      onClose(); // 실패해도 모달을 닫음
    } finally {
      setLoading(false); // 업로드 종료 시 로딩 상태 해제
    }
  };

  return (
    <div className="file-upload">
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>파일 업로드 중입니다...</p>
        </div>
      )}

      {/* 파일 선택 커스텀 버튼 */}
      <label className="custom-file-upload">
        <input type="file" onChange={handleFileChange} />
        {selectedFile ? selectedFile.name : 'Choose a file'}
      </label>

      <div className="file-upload-actions">
        <button onClick={handleUpload} disabled={loading}>
          {loading ? 'Uploading...' : 'Upload File'}
        </button>
        <button onClick={onClose} disabled={loading}>Cancel</button>
      </div>
    </div>
  );
};

export default FileUpload;