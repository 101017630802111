import React, { useState } from 'react';
import FileUpload from './FileUpload'; // 파일 업로드 컴포넌트
import UploadModal from './UploadModal'; // 모달 컴포넌트
import { handleDownload } from '../services/api';

const Directory = ({ id, name, directories = [], files = [], onAddItem, isLoggedIn, isAdmin, isManager }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태 추가

  const handleFileUpload = (fileName) => {
    onAddItem(id, fileName); // 디렉토리에 파일 추가 (parentId로 id 사용)
    setShowModal(false); // 파일 업로드 후 모달 닫기
    console.log("File uploaded: ", fileName);
  };

  return (
    <div className="directory">
      <div className="directory-header">
        <span onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? '📂' : '📁'} {name}
        </span>
        {isLoggedIn && (isAdmin || isManager) && (
          <>
            <span className="add-dir-btn" onClick={() => onAddItem(id, false)}>➕📁</span>
            <span className="add-file-btn" onClick={() => setShowModal(true)}>➕📄</span>
          </>
        )}
      </div>
      {isExpanded && (
        <div className="directory-contents">
          {isLoading && (
            <div className="loading-overlay">
              <div className="loading-spinner"></div>
              <p>파일 다운로드 중입니다...</p>
            </div>
          )}
          {/* 디렉토리 목록 표시 (디렉토리가 있을 때만 렌더링) */}
          {Array.isArray(directories) && directories.length > 0 && (
            directories.map((dir) => (
              <Directory
                key={dir.directoryId} // 고유한 ID를 key로 사용
                id={dir.directoryId} // directoryId를 id로 전달
                name={dir.directoryName}
                directories={dir.directories}
                files={dir.files}
                onAddItem={onAddItem}
                isLoggedIn={isLoggedIn} // 로그인 상태를 하위 디렉토리에도 전달
                isAdmin={isAdmin} // 관리자 여부를 하위 디렉토리에도 전달
                isManager={isManager} // 매니저 여부를 하위 디렉토리에도 전달
              />
            ))
          )}
          {/* 파일 목록 표시 (파일이 있을 때만 렌더링) */}
          {Array.isArray(files) && files.length > 0 && (
            files.map((file, index) => (
              <div key={index} className="file">
                {isLoggedIn ? (
                  <a 
                    onClick={() => handleDownload(file.url, setIsLoading)} // 다운로드 시 로딩 상태 전달
                    style={{ color: '#21a1f1', textDecoration: 'underline', cursor: 'pointer' }}>
                    📝 {file.fileName}
                  </a>
                ) : (
                  <span>📝 {file.fileName}</span> // 로그인되지 않았을 때는 파일 이름만 표시
                )}
              </div>
            ))
          )}
        </div>
      )}
      {showModal && (
        <UploadModal onClose={() => setShowModal(false)}>
          <FileUpload 
            directoryId={id}  // ID를 전달
            onCreateFile={handleFileUpload}
            onClose={() => setShowModal(false)} // 모달 닫기 함수 전달
          />
        </UploadModal>
      )}
    </div>
  );
};

export default Directory;