import React, { useState } from 'react';
import Directory from './Directory';
import Modal from './Modal';
import FileUpload from './FileUpload';
import { createDirectory } from '../services/api'; // API 요청 함수 추가

const FileTree = ({ directories, isLoggedIn, isAdmin, isManager }) => {
  const [showModal, setShowModal] = useState(false);
  const [newItemName, setNewItemName] = useState('');
  const [currentParent, setCurrentParent] = useState(null);
  const [isFile, setIsFile] = useState(false);

  const createItem = async (newItemName) => {
    // 디렉토리 생성 API 호출
    if (!newItemName.trim()) {
      alert('디렉토리 이름을 입력하세요.');
      return; // 함수 종료
    }
    if (!isFile) {
      await createDirectory(currentParent, newItemName); // currentParent를 parentId로 사용
      alert('디렉토리가 생성되었습니다.');
    } else {
      alert('디렉토리 생성 중 오류가 발생했습니다.');
    }
    setShowModal(false);
    setNewItemName('');
    window.location.reload();
  };

  const handleAddClick = (parentId, isFile = false) => { // parentName을 parentId로 변경
    setCurrentParent(parentId); // parentId를 설정
    setIsFile(isFile);
    setShowModal(true);
  };

  return (
    <div className="file-tree">
      <div style={{
        color: '#cc0000',
        marginBottom: '20px',
      }}>
        ⚠️ 파일이 깨지면 반디집으로 압축을 해제해 주세요.
      </div>
      {isLoggedIn && (isAdmin || isManager) && (
        <div style={{
          marginBottom: '20px',
          padding: '10px',
          backgroundColor: '#f0f0f0',
          border: '2px dashed #007bff',
          borderRadius: '5px',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          width: 'fit-content'
        }} onClick={() => handleAddClick(null, false)} className="add-directory">
          📁➕ 새 디렉토리 추가
        </div>
      )}

      {directories.map((dir) => (
        <Directory
          key={dir.directoryId} // 디렉토리 ID를 키로 사용
          id={dir.directoryId} // 디렉토리 ID 추가
          name={dir.directoryName} // 디렉토리 이름
          directories={dir.directories} // 하위 디렉토리
          files={dir.files} // 파일 목록
          onAddItem={handleAddClick}
          isLoggedIn={isLoggedIn}
          isAdmin={isAdmin}
          isManager={isManager}
        />
      ))}

      {showModal && (
        <Modal onClose={() => setShowModal(false)} onSave={() => createItem(newItemName)}>
          {isFile ? (
            <FileUpload directoryId={currentParent} onCreateFile={createItem} />
          ) : (
            <input
              type="text"
              value={newItemName}
              placeholder="디렉토리 이름을 입력하세요"
              onChange={(e) => setNewItemName(e.target.value)}
            />
          )}
        </Modal>
      )}
    </div>
  );
};

export default FileTree;