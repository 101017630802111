import React from 'react';
import '../styles/UploadModal.css'; // 모달 스타일링

const UploadModal = ({ onClose, children }) => {
  return (
    <div className="modal-backdrop">
      <div className="modal-content">
            {children}
      </div>
    </div>
  );
};

export default UploadModal;