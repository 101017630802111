import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import FileTree from './components/FileTree';
import ViewUser from './components/ViewUser';
import { checkSession, getDirectories, recordVisit } from './services/api';
import VisitorStats from './components/VisitorStats';
import './styles/App.css';

function AppContent({ isLoggedIn, directories, isManager, isAdmin }) {
  // useLocation 훅을 사용하여 현재 경로를 추적
  const location = useLocation(); // 현재 경로를 추적

  return (
    <div className="container">
      <Routes>
        {/* admin일 경우에만 VisitorStats 렌더링 */}
        {isAdmin && (
          <Route 
            path="/visitor-stats" 
            element={<VisitorStats />} 
          />
        )}
        <Route 
          path="/" 
          element={
            !location.pathname.startsWith('/visitor-stats') ? (
              <>
                <div className="content">
                  <FileTree
                    directories={directories}
                    isLoggedIn={isLoggedIn}
                    isManager={isManager}
                    isAdmin={isAdmin}
                  />
                </div>
                {isAdmin && (
                  <div className="content">
                    <ViewUser />
                  </div>
                )}
              </>
            ) : null
          }
        />
      </Routes>
    </div>
  );
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [directories, setDirectories] = useState([]);

  useEffect(() => {
    const recordVisitor = async () => {
      const isSuccess = await recordVisit();
      if (!isSuccess) {
        console.warn('Failed to record visitor');
      }
    };

    recordVisitor();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      const checkSessionInterval = setInterval(() => {
        checkSession()
          .then(response => {
            if (!response.isLoggedIn) {
              alert("세션이 만료되었습니다.");
              window.location.href = "/"; // 세션 만료 시 리디렉션
            }
            setIsLoggedIn(response.isLoggedIn);
            setIsManager(response.isManager);
            setIsAdmin(response.isAdmin);
          })
          .catch(() => {
            alert("세션 확인 중 오류가 발생했습니다.");
            setIsLoggedIn(false); // 오류 발생 시 로그아웃 처리
            window.location.href = "/"; // 리디렉션
          });
      }, 6*60000);

      return () => clearInterval(checkSessionInterval);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    checkSession()
      .then(response => {
        setIsLoggedIn(response.isLoggedIn);
        setIsManager(response.isManager);
        setIsAdmin(response.isAdmin);
      })
      .catch(() => {
        setIsLoggedIn(false);
      });
  }, []);

  useEffect(() => {
    const fetchDirectories = async () => {
      try {
        const response = await getDirectories();
        setDirectories(response);
      } catch (error) {
        console.error('Error fetching directories:', error);
      }
    };

    fetchDirectories();
  }, []);

  return (
    <div className="App">
      <Router>
        <Navbar
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          isManager={isManager}
          setIsManager={setIsManager}
          isAdmin={isAdmin}
          setIsAdmin={setIsAdmin}
        />
        <AppContent
          isLoggedIn={isLoggedIn}
          directories={directories}
          isManager={isManager}
          isAdmin={isAdmin}
        />
      </Router>
    </div>
  );
}

export default App;