import React, { useState, useEffect } from 'react';
import { getTotalVisitorCount, getVisitorMonthlyStats } from '../services/api';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

// Chart.js 설정
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// 차트 데이터와 옵션을 컴포넌트 외부로 추출하여 가독성을 높임
const getChartData = (monthlyStats) => ({
  labels: monthlyStats.map(stat => stat.targetRange), 
  datasets: [
    {
      label: '월별 방문자 수',
      data: monthlyStats.map(stat => stat.visitorCount),
      borderColor: 'rgba(75, 192, 192, 1)',
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      fill: true,
    },
  ],
});

const getChartOptions = () => ({
  responsive: true,
  plugins: {
    tooltip: {
      callbacks: {
        label: (tooltipItem) => `Visitors: ${tooltipItem.raw}`,
      },
    },
  },
  maintainAspectRatio: false,
});

const VisitorStatsPage = () => {
  const [totalVisitorCount, setTotalVisitorCount] = useState(0);
  const [monthlyStats, setMonthlyStats] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // 데이터 가져오는 함수
    const fetchVisitorData = async () => {
      try {
        const totalCount = await getTotalVisitorCount();
        const monthlyData = await getVisitorMonthlyStats();
        setTotalVisitorCount(totalCount.visitorCount || 0);
        setMonthlyStats(monthlyData);
      } catch (error) {
        console.error('Error fetching visitor data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVisitorData();
  }, []);

  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <p>Loading...</p>
      </div>
    );
  }

  const chartData = getChartData(monthlyStats);
  const chartOptions = getChartOptions();

  return (
    <div className="content" style={styles.container}>
      <h1 style={styles.header}>방문자 통계</h1>

      {/* 총 방문자 수 표시 */}
      <p style={styles.totalVisitors}>
        <strong>총 방문자 수 :</strong> {totalVisitorCount}
      </p>

      {/* 월별 방문자 수 차트 */}
      <div style={styles.chartContainer}>
        <Line data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

// 스타일 추가
const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  header: {
    fontSize: '2rem',
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
  },
  totalVisitors: {
    fontSize: '1.2rem',
    fontWeight: '600',
    marginBottom: '20px',
    color: '#555',
  },
  chartContainer: {
    position: 'relative',
    height: '400px',
    marginTop: '20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    fontSize: '1.5rem',
  },
};

export default VisitorStatsPage;