import React from 'react';
import '../styles/Modal.css'; // 모달 스타일링

const Modal = ({ onClose, onSave, children }) => {
  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        {children}
        <div className="modal-actions">
          <button onClick={onSave}>Save</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;