import axios from 'axios';

export const fetchFiles = async () => {
  try {
    const response = await axios.get(`/api/files`);
    return response.data;
  } catch (error) {
    console.error('Error fetching files:', error);
    throw error;
  }
};

// 로그인 API 호출
export const loginUser = async (credentials) => {
  try {
    const response = await axios.post(`/api/users/login`, credentials, {
      withCredentials: true, // 쿠키를 포함시켜 요청
    });
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

// 로그아웃 API 호출
export const logoutUser = async () => {
  try {
    const response = await axios.get(`/api/users/logout`);
    return response.data;
  } catch (error) {
    console.error('Error logging out:', error);
    throw error;
  }
};

// 세션 확인 API 호출
export const checkSession = async () => {
  try {
    const response = await axios.get(`/api/users/session-check`);
    return response.data;
  } catch (error) {
    console.error('Error checking session:', error);
    throw error;
  }
};

// 파일 업로드 API 호출
export const uploadFile = async (directoryId, file, setLoading) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    setLoading(true); // 업로드 시작할 때 로딩 상태를 true로 설정
    const response = await axios.post(`/api/files/upload?directoryId=${directoryId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  } finally {
    setLoading(false); // 업로드가 끝나면 로딩 상태를 false로 설정
    window.location.reload(); // 파일 업로드 후 페이지 새로고침
  }
};

export const createDirectory = async (parentId, directoryName) => {
  try {
      const response = await axios.post(`/api/directories`, {
        parentId,
        directoryName
      });
      return response.data;
  } catch (error) {
      console.error('Error creating directory:', error);
      throw error;
  }
};

export const getDirectories = async () => {
  try {
      const response = await axios.get(`/api/directories/lists`);
      return response.data;
  } catch (error) {
      console.error('Error fetching directories:', error);
      throw error;
  }
};

export const handleDownload = async (url, setLoading) => {
  try {
    setLoading(true); // 다운로드 시작 시 로딩 상태를 true로 설정

    // responseType을 'blob'으로 설정
    const response = await axios.get(`/api/files/download?url=${url}`, { responseType: 'blob' });

    if (response.status === 200) {
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = url.split('_').slice(1).join('_'); // 파일 이름 설정
      link.click();
    } else {
      console.error('파일 다운로드에 실패했습니다.');
    }
  } catch (error) {
    console.error('다운로드 중 오류가 발생했습니다.', error);
  } finally {
    setLoading(false); // 다운로드 완료 또는 실패 시 로딩 상태를 false로 설정
  } 
};

export const viewUser = async () => {
  try {
    const response = await axios.get(`/api/users/active-users`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user:', error);
    throw error;
  }
}

export const inactiveUser = async (userId) => {
  try {
    const response = await axios.get(`/api/users/inactive-user?userId=${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error inactive user:', error);
    throw error;
  }
}

export const recordVisit = async () => {
  try {
    const response = await fetch(`/api/visitors`, {
      method: 'GET',
      credentials: 'include', // 쿠키 포함
    });
    if (!response.ok) {
      throw new Error('Failed to record visitor');
    }
    return response.status === 200; // 성공적으로 기록하면 true 반환
  } catch (error) {
    console.error('Error recording visitor:', error);
    return false;
  }
};

// 총 방문자 수 조회
export const getTotalVisitorCount = () => {
  return axios.get(`/api/visitors/count`)
    .then(response => response.data)
    .catch(error => {
      console.error('Error fetching total visitor count:', error);
      throw error;
    });
};

// 월별 방문자 수 통계 조회
export const getVisitorMonthlyStats = () => {
  return axios.get(`/api/visitors/monthly`)
    .then(response => response.data)
    .catch(error => {
      console.error('Error fetching monthly visitor stats:', error);
      throw error;
    });
};