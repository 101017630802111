import { useEffect, useState } from "react";
import { viewUser, inactiveUser } from "../services/api"; // inactiveUser 불러오기
import '../styles/ViewUser.css'; // 스타일을 위한 CSS 파일 불러오기

const ViewUser = () => {
    const [users, setUsers] = useState(null); // 사용자 정보를 저장할 상태
    const [loading, setLoading] = useState(true); // 로딩 상태
    const [error, setError] = useState(null); // 에러 상태

    // useEffect는 항상 호출되어야 함
    useEffect(() => {
        // API 호출
        viewUser()
            .then((response) => {
                console.log('View User:', response);
                setUsers(response); // 응답 데이터를 users 상태로 저장
                setLoading(false); // 로딩 완료
            })
            .catch(error => {
                console.error('Error viewing user:', error);
                setError(error); // 에러 발생 시 에러 상태로 저장
                setLoading(false); // 로딩 완료
            });
    }, []); // 컴포넌트 마운트 시 한 번 호출

    // 로그아웃 버튼 클릭 시 호출되는 함수
    const handleLogoutClick = async (userId) => {
        try {
            const response = await inactiveUser(userId); // inactiveUser API 호출
            alert(`${response}`); // 성공 메시지 설정
        } catch (error) {
            console.error('Error logging out user:', error);
            alert(`강제 로그아웃에 실패하였습니다.`); // 실패 메시지 설정
        }
        window.location.reload(); // 페이지 새로고침
    };

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    if (error) {
        return <div className="error">Error: {error.message}</div>;
    }

    if (!users) {
        return <div>접속중인 사용자가 없습니다.</div>;
    }

    return (
        <div className="view-user">
            <h2 className="header">접속중인 사용자</h2>
            <div className="user-list">
                <ul>
                    {users.map((user, index) => (
                        <li key={index} className="user-item">
                            <span className="user-name">{user.name}</span>
                            <button 
                                className="logout-button" 
                                onClick={() => handleLogoutClick(user.userId)}>
                                강제 로그아웃
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ViewUser;